import fetch from 'isomorphic-fetch';

import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {UMF_ADMIN_TOKEN} from '../constants/constants';

console.log('backend url', process.env.GATSBY_APP_SERVER);
const httpLink = createHttpLink({
  uri: process.env.GATSBY_APP_SERVER,
  fetch,
  credentials: 'include',
});

const authLink = setContext((_, {headers}) => {
  // return the headers to the context so httpLink can read them
  const token = localStorage.getItem(UMF_ADMIN_TOKEN);
  // console.log('user token', token);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const cacheOptions = {
  typePolicies: {},
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default client;
